import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TCrop,
  TPartnerData,
  TCropWeightModalData,
  TAdditionDeduction,
  TgroupItem,
  TIndividualWeightItem,
} from "../types";
import {
  TBillCrop,
  TBillInfo,
  TMultiBill,
  TBillBooReducerPartnerData,
  TBillHistory,
  TGetWriterById,
  TNotificatons,
} from "../types/bill-book";
import { TCaBillSetting } from "../types/settings";
import moment from "moment";
// type TPartnerInfo = {
//   partnerId: number;
//   partnerType: string;
//   uniqueCroItems: TCrop[];
//   crops: TBillCrop[];
//   cropsSummary: {
//     [cropId: number]: number;
//   };
//   grossTotalVal: number;
// };
type TInitialState = {
  openModal: boolean;
  openBillViewModal: boolean;
  bill_id: number;
  caBSeq: number;
  group_id: number;
  billType: "" | "BUY" | "SELL";
  billCreationMode: "" | "single" | "multiple";
  reloadBillTable: boolean;
  billEdit: boolean;
  billStatus: string;
  currentStep: number;
  writerById: TGetWriterById | null;
  grossTotalChanged: boolean;
  quantityChanged: boolean;
  selectedPartner: Array<TPartnerData>;
  editCropPartnerId: number;
  moveNextPartner: boolean;
  partnerOutStandingBalancedata: {
    [partnerId: number]: {
      outStandingBal: number;
      outStandingAdv: number;
      finalOutStBal?: number;
      finalLedgerBal?: number;
      billId?: number;
      caBSeq?: number;
    };
  };
  selectedTransporter: Array<TPartnerData>;
  selectedDate: string;
  preferedCropIds: Array<number>;
  cropWeights: TCropWeightModalData;
  partnersArr: number[];
  selectedDateVal: string;
  grossTotal: number;
  cropTotalUnits: number;
  grpWiseData: Array<TgroupItem>;
  partners: {
    [partnerId: number]: TBillBooReducerPartnerData;
  };
  partnerCropTableStatus: {
    [partnerId: number]: { hasTotal: boolean; cropAdditionCompleted: boolean };
  };
  billAmountData: TAdditionDeduction;
  customBillAmountData: {
    [settingId: number]: TCaBillSetting;
  };
  partnerName: string; // this is using for bill view slider top heading
  singleBillViewData: TBillInfo | null;
  multiBillViewData: TMultiBill | null;
  partnerShortName: string;
  fromLedgers: boolean;
  fromTransporters: boolean;
  billHistoryData: Array<TBillHistory>;
  fromSmartboard: boolean;
  notifications: TNotificatons[];
  isPendingBill: boolean,
  fromSmartChart: boolean;
  fromAddBill: boolean,
  fromSalesSummary: boolean,
  outStandingBillStatus: boolean;
  sellOutStandingBillStatus: boolean;
  buyOutStandingBillStatus: boolean;
  selectedMultiPartnerIndex: number,
};

const initialState: TInitialState = {
  openModal: false,
  openBillViewModal: false,
  grpWiseData: [],
  bill_id: 0,
  caBSeq: 0,
  group_id: 0,
  billType: "",
  billStatus: "",
  billCreationMode: "",
  reloadBillTable: false,
  billEdit: false,
  currentStep: 0,
  writerById: null,
  quantityChanged: false,
  grossTotalChanged: false,
  selectedPartner: [],
  editCropPartnerId: 0,
  moveNextPartner: false, // step2 multi bill
  partnerOutStandingBalancedata: {},
  selectedTransporter: [],
  selectedDate: moment().format("YYYY-MM-DD"),
  grossTotal: 0,
  cropTotalUnits: 0,
  preferedCropIds: [],
  cropWeights: {
    showModal: "",
    partnerId: 0,
    cropId: 0,
    cropName: "",
    cropImage: "",
    crop_position: 0,
    quantity: 0,
    unitType: "",
    totalWeight: 0,
    totalWastage: 0,
    data: [],
    avgRate: 0,
    total: 0
  },
  partnersArr: [],
  partners: {},
  partnerCropTableStatus: {},
  selectedDateVal: moment().format("YYYY-MM-DD"),
  notifications: [],
  billAmountData: {
    // summary
    grossTotal: 0,
    total: 0,
    includeComm: false,
    isShown: false,
    includeRetComm: false,
    addRetComm: false,
    includeRentComm: false,
    outBalformStatusvalue: false,
    addSkipIndividualStatus: false,
    outStandingBal: 0,
    outStandingAdv: 0,
    actualPayble: 0,
    actualReceivable: 0,
    finalLedgerBal: 0,
    finalOutBal: 0,
    totalPayble: 0,
    totalReceivable: 0,
    //comission inputs data
    commissionPer: 0,
    commissionTotal: 0,
    returnCommissionPer: 0,
    returnCommissionTotal: 0,
    transportationValue: 0,
    transportationUnit: 0,
    transportationTotal: 0,
    govtLevies: 0,
    mandiFee: 0,
    mandiFeeTotal: 0,
    otherFee: 0,
    comments: "",
    advances: 0,
    rentValue: 0,
    rentUnit: 0,
    rentTotal: 0,
    labourChargesValue: 0,
    labourChargesUnit: 0,
    labourChargesTotal: 0,
    cashPaid: 0,
    billAmt: 0,
    cashPaidCmnt: "",
    cashRcvd: 0,
    cashRcvdCmnt: "",
    less: false,
    misc: 0,
    billId: 0,
    caBSeq: 0,
    groupId: 0,
  },
  customBillAmountData: {},
  partnerName: "",
  singleBillViewData: null,
  multiBillViewData: null,
  partnerShortName: "",
  fromLedgers: false,
  fromTransporters: false,
  billHistoryData: [],
  fromSmartboard: false,
  isPendingBill: false,
  fromSmartChart: false,
  fromAddBill: false,
  fromSalesSummary: false,
  outStandingBillStatus: false,
  sellOutStandingBillStatus: false,
  buyOutStandingBillStatus: false,
  selectedMultiPartnerIndex: 0,
};

const billBookSlice = createSlice({
  name: "billBook",
  initialState: initialState,
  reducers: {
    opeBillCreationModal: (
      state,
      action: PayloadAction<{
        billType: "BUY" | "SELL";
        billCreationMode: "single" | "multiple";
        fromSmartboard?: boolean;
        billDate?: string;
      }>
    ) => {
      state.openModal = true;
      state.reloadBillTable = false;
      state.currentStep = 1;
      state.billType = action.payload.billType;
      state.billCreationMode = action.payload.billCreationMode;
      if (action.payload.fromSmartboard) {
        state.fromSmartboard = action.payload.fromSmartboard;
      }
      if (action.payload.billDate) {
        state.selectedDate = action.payload.billDate;
      }
      state.isPendingBill = false;
    },
    closeBillCreationModal: (
      state,
      action: PayloadAction<{
        clearState?: boolean;
        newBill?: boolean;
        reloadBillTable?: boolean;
        fromSmartboard?: boolean;
      }>
    ) => {
      if (action.payload.newBill) {
        return {
          ...initialState,
          openModal: true,
          currentStep: 1,
          billType: state.billType,
          billCreationMode: state.billCreationMode,
          selectedDate: state.selectedDate,
        };
      }
      if (action.payload.clearState) {
        let selectedDate = state.selectedDate;
        return {
          ...initialState,
          selectedDate: selectedDate,
          reloadBillTable: action.payload.reloadBillTable || false,
        };
      } else if (state.billEdit === true) {
        state.openModal = false;
        state.reloadBillTable = action.payload.reloadBillTable || false;
      } else {
        return {
          ...initialState,
          selectedDate: state.selectedDate,
          reloadBillTable: action.payload.reloadBillTable || false,
        };
      }
    },
    setCurrentStep: (
      state,
      action: PayloadAction<{
        step: number;
        clearState?: boolean;
        partnerId?: number;
      }>
    ) => {
      if (
        action.payload.step === 3 &&
        state.editCropPartnerId &&
        state.partnerCropTableStatus[state.editCropPartnerId]
          .cropAdditionCompleted === false
      ) {
        state.partnerCropTableStatus[
          state.editCropPartnerId
        ].cropAdditionCompleted = true;
      }
      if (action.payload.step === 1 && state.editCropPartnerId) {
        state.editCropPartnerId = 0;
      }
      if (action.payload.partnerId) {
        state.editCropPartnerId = action.payload.partnerId;
      }
      if (state.currentStep === 1 && action.payload.step === 2) {
        state.editCropPartnerId = state.selectedPartner[0].partyId;
      }
      if (action.payload.step === 2 && state.currentStep === 3) {
        state.grossTotalChanged = false;
        state.quantityChanged = false;
      }
      state.currentStep = action.payload.step;
    },
    setSelectedPartner: (state, action) => {
      state.selectedPartner = action.payload;
    },
    setSelectedTransporter: (
      state,
      action: PayloadAction<{ transporter: TPartnerData; partnerId: number }>
    ) => {
      state.selectedTransporter = [action.payload.transporter];
      if (
        action.payload.partnerId &&
        state.partners[action.payload.partnerId]
      ) {
        state.partners[action.payload.partnerId].transporter =
          action.payload.transporter;
      }
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    changeCropPartner: (
      state,
      action: PayloadAction<{
        currentPartyId: number;
        newPartyId: number;
        newPartner?: TPartnerData;
      }>
    ) => {
      const { currentPartyId, newPartyId } = action.payload;
      let currentPartnerCropData = Object.create(
        state.partners[currentPartyId]
      );
      delete state.partners[currentPartyId];
      state.partners[newPartyId] = currentPartnerCropData;
      if (
        state.billCreationMode === "single" &&
        typeof action.payload.newPartner !== "undefined"
      ) {
        state.selectedPartner = [action.payload.newPartner];
        state.partnerCropTableStatus[newPartyId] =
          state.partnerCropTableStatus[currentPartyId];
        delete state.partnerCropTableStatus[currentPartyId];
        state.editCropPartnerId = newPartyId;
      }
    },
    setEditCropPartnerId: (
      state,
      action: PayloadAction<{ partnerId: number }>
    ) => {
      state.editCropPartnerId = action.payload.partnerId;
    },
    addCrop: (
      state,
      action: PayloadAction<{
        partnerId: number;
        partnerType: string;
        crops: Array<TBillCrop>;
      }>
    ) => {
      const { partnerId, partnerType, crops } = action.payload;
      let newPartners = { ...state.partners };
      if (!newPartners[partnerId]) {
        newPartners[partnerId] = {
          partnerId: partnerId,
          partnerType: partnerType,
          uniqueCroItems: [],
          crops: [],
          cropsSummary: {},
          grossTotalVal: 0,
          totalCropsCount: 0,
          deletedCrops: [],
        };
      }
      let parnerInfo = newPartners[partnerId];
      let cropItemQuantitySummary = { ...parnerInfo.cropsSummary };
      let seletedCropIds = parnerInfo.uniqueCroItems.map((crop) => crop.cropId);
      const new_crops: TCrop[] = [];
      for (let crop of crops) {
        if (cropItemQuantitySummary[crop.cropId]) {
          cropItemQuantitySummary[crop.cropId] += 1;
        } else {
          cropItemQuantitySummary[crop.cropId] = 1;
          //parnerInfo.uniqueCroItems.push(crop);
        }
        parnerInfo.crops.push(crop);
        if (seletedCropIds.indexOf(crop.cropId) === -1) {
          new_crops.push(crop);
        }
      }
      parnerInfo.cropsSummary = cropItemQuantitySummary;
      parnerInfo.uniqueCroItems = [...parnerInfo.uniqueCroItems, ...new_crops];
      state.partners[partnerId] = parnerInfo;
      state.partnerCropTableStatus[partnerId].hasTotal = false;
    },
    removeCrop: (
      state,
      action: PayloadAction<{
        partnerId: number;
        crop: TBillCrop;
        cropIndex: number;
      }>
    ) => {
      const { partnerId, crop, cropIndex } = action.payload;
      let parnerInfo: TBillBooReducerPartnerData = JSON.parse(
        JSON.stringify(state.partners[partnerId])
      );
      if (state.billEdit) {
        let cropObj = { ...crop };
        cropObj.status = 0;
        parnerInfo.deletedCrops.push(cropObj);
      }
      parnerInfo.crops.splice(cropIndex, 1);
      parnerInfo.cropsSummary[crop.cropId] -= 1;
      if (parnerInfo.cropsSummary[crop.cropId] <= 0) {
        delete parnerInfo.cropsSummary[crop.cropId];
        if (state.preferedCropIds.indexOf(crop.cropId) === -1) {
          let i = parnerInfo.uniqueCroItems.findIndex(
            (cro) => cro.cropId === crop.cropId
          );
          parnerInfo.uniqueCroItems.splice(i, 1);
        }
      }
      state.partners[partnerId] = parnerInfo;
      state.partnerCropTableStatus[partnerId].hasTotal = false;
      state.quantityChanged = true;
      state.grossTotalChanged = true;
    },
    updateCropInfo: (
      state,
      action: PayloadAction<{
        partnerId: number;
        cropId: number;
        cropIndex: number;
        cropInfo: TBillCrop;
      }>
    ) => {
      const { partnerId, cropId, cropIndex, cropInfo } = action.payload;
      if (
        state.partners[partnerId] &&
        state.partners[partnerId].crops[cropIndex].cropId === cropId
      ) {
        state.partners[partnerId].crops[cropIndex] = cropInfo;
      }
      if (cropInfo.total) {
        let hasAllCropTotalValue = true;
        for (let crop of state.partners[partnerId].crops) {
          if (!crop.total) {
            hasAllCropTotalValue = false;
            break;
          }
        }
        state.partnerCropTableStatus[partnerId].hasTotal = hasAllCropTotalValue;
      } else {
        state.partnerCropTableStatus[partnerId].hasTotal = false;
      }
    },
    setUniqueBillingCrops: (
      state,
      action: PayloadAction<{
        partnerId: number;
        partnerType: string;
        crops: TCrop[];
      }>
    ) => {
      const { partnerId, partnerType, crops } = action.payload;
      let newPartners = { ...state.partners };
      if (!newPartners[partnerId]) {
        newPartners[partnerId] = {
          partnerId: partnerId,
          partnerType: partnerType,
          uniqueCroItems: crops,
          crops: [],
          cropsSummary: {},
          grossTotalVal: 0,
          totalCropsCount: 0,
          deletedCrops: [],
        };
        if (
          state.selectedTransporter.length === 1 &&
          state.billCreationMode === "single"
        ) {
          newPartners[partnerId].transporter = state.selectedTransporter[0];
        }
      }
      let parnerInfo = newPartners[partnerId];
      state.partners[partnerId] = parnerInfo;
      if (state.preferedCropIds.length === 0) {
        state.preferedCropIds = crops.map((crop) => crop.cropId);
      }
      if (!state.partnerCropTableStatus[partnerId]) {
      }
      if (!state.partnerCropTableStatus[partnerId]) {
        state.partnerCropTableStatus[partnerId] = {
          hasTotal: false,
          cropAdditionCompleted: false,
        };
      }
      if (state.billEdit === true) {
        state.partnerCropTableStatus[action.payload.partnerId].hasTotal = true;
        state.partnerCropTableStatus[
          action.payload.partnerId
        ].cropAdditionCompleted = true;
      }
    },
    clonedCrops: (
      state,
      action: PayloadAction<{
        partnerId: number;
        crop: TBillCrop;
        cropIndex: number;
      }>
    ) => {
      const { partnerId, crop, cropIndex } = action.payload;
      let parnerInfo: TBillBooReducerPartnerData = JSON.parse(
        JSON.stringify(state.partners[partnerId])
      );
      parnerInfo.crops.splice(cropIndex + 1, 0, crop);
      parnerInfo.cropsSummary[crop.cropId] += 1;
      state.partners[partnerId] = parnerInfo;
      if (!crop.total) {
        state.partnerCropTableStatus[partnerId].hasTotal = false;
      }
      state.quantityChanged = true;
      state.grossTotalChanged = true;
    },
    cropAdditionCompleted: (
      state,
      action: PayloadAction<{ partnerId: number }>
    ) => {
      state.partnerCropTableStatus[
        action.payload.partnerId
      ].cropAdditionCompleted = true;
    },
    updateCropTableStatus: (
      state,
      action: PayloadAction<{
        partnerId: number;
        hasTotal?: boolean;
        cropAdditionCompleted?: boolean;
      }>
    ) => {
      if (action.payload.hasTotal) {
        state.partnerCropTableStatus[action.payload.partnerId].hasTotal = true;
      }
      if (action.payload.cropAdditionCompleted) {
        state.partnerCropTableStatus[
          action.payload.partnerId
        ].cropAdditionCompleted = true;
      }
    },
    showIdividualWeightModal: (
      state,
      action: PayloadAction<TCropWeightModalData>
    ) => {
      state.cropWeights = { ...action.payload, showModal: true };
    },
    hideIdividualWeightModal: (state, action) => {
      state.cropWeights.showModal = false;
    },
    updateIndividualWeight: (
      state,
      action: PayloadAction<{
        partnerId: number;
        cropId: number;
        cropPosition: number;
        quantity: number;
        totalWeight: number;
        totalWastage: number;
        data: Array<TIndividualWeightItem>;
        removedItems?: Array<TIndividualWeightItem>;
        rate: number,
        total: number,
      }>
    ) => {
      state.partners[action.payload.partnerId].crops[
        action.payload.cropPosition
      ].individualWeightInfo = {
        quantity: action.payload.quantity,
        totalWeight: action.payload.totalWeight,
        totalWastage: action.payload.totalWastage,
        data: action.payload.data,
        removedData: action.payload.removedItems,
      };
      state.partners[action.payload.partnerId].crops[
        action.payload.cropPosition
      ].qty = action.payload.quantity;
      state.partners[action.payload.partnerId].crops[
        action.payload.cropPosition
      ].weight = action.payload.totalWeight;
      state.partners[action.payload.partnerId].crops[
        action.payload.cropPosition
      ].wastage = Math.abs(action.payload.totalWastage);
      state.cropWeights.showModal = false;
      if (state.fromAddBill) {
        state.partners[action.payload.partnerId].crops[
          action.payload.cropPosition
        ].rate = action.payload.rate;
        state.partners[action.payload.partnerId].crops[
          action.payload.cropPosition
        ].total = action.payload.total;
      }
      state.quantityChanged = true;
    },
    calculateCropsSummary: (state) => {
      let prevCropTotalUnits = state.cropTotalUnits;
      let partnerIds = Object.keys(state.partners);
      let grossTotal = 0;
      let totalCrop = 0;
      for (let partnerId of partnerIds) {
        let partnerGrossTotal = 0;
        let partnerCropsCount = 0;
        for (let crop of state.partners[parseInt(partnerId)].crops) {
          grossTotal += crop.total;
          totalCrop += Number(crop.qty);
          partnerGrossTotal += crop.total;
          partnerCropsCount += Number(crop.qty);
        }
        state.partners[parseInt(partnerId)].grossTotalVal = partnerGrossTotal;
        state.partners[parseInt(partnerId)].totalCropsCount = partnerCropsCount;
      }
      state.grossTotal = grossTotal;
      state.billAmountData.actualPayble = grossTotal;
      state.cropTotalUnits = totalCrop;
      state.billAmountData.grossTotal = grossTotal;
      if (state.billCreationMode == 'single') {
        state.billAmountData.total = grossTotal;
      }
      state.billAmountData.actualPayble = grossTotal;
      state.billAmountData.actualReceivable = grossTotal;
      state.billAmountData.finalLedgerBal = grossTotal;
      state.billAmountData.finalOutBal = grossTotal;
      state.billAmountData.transportationUnit = totalCrop;
      state.billAmountData.rentUnit = totalCrop;
      state.billAmountData.labourChargesUnit = totalCrop;
    },
    initializeBillData: (
      state,
      action: PayloadAction<{
        grossTotal?: number;
        partnerWiseGrossTotal?: { partnerId: number; grossTotal: number }[];
      }>
    ) => {
      if (action.payload.grossTotal) {
        state.grossTotal = action.payload.grossTotal;
      }
      if (action.payload.partnerWiseGrossTotal) {
        for (let parnerGrossTotal of action.payload.partnerWiseGrossTotal) {
          state.partners[parnerGrossTotal.partnerId].grossTotalVal =
            parnerGrossTotal.grossTotal;
        }
      }
    },
    updateOutStandingBalance: (
      state,
      action: PayloadAction<{
        outStandingBal?: number;
        outStandingAdv?: number;
        finalOutStBal?: number; // while edit bill
        finalLedgerBal?: number; // while edit bill
        partnerId?: number;
        billId?: number;
        caBSeq?: number;
      }>
    ) => {
      if (typeof action.payload.outStandingBal !== "undefined") {
        state.billAmountData.outStandingBal = action.payload.outStandingBal;
      }
      if (typeof action.payload.outStandingAdv !== "undefined") {
        state.billAmountData.outStandingAdv = action.payload.outStandingAdv;
      }
      if (action.payload.partnerId) {
        if (
          typeof state.partnerOutStandingBalancedata[
          action.payload.partnerId
          ] === "undefined"
        ) {
          state.partnerOutStandingBalancedata[action.payload.partnerId] = {
            outStandingBal: 0,
            outStandingAdv: 0,
          };
        }
        if (
          typeof action.payload.outStandingBal !== "undefined" &&
          typeof action.payload.outStandingAdv !== "undefined"
        ) {
          state.partnerOutStandingBalancedata[
            action.payload.partnerId
          ].outStandingBal = action.payload.outStandingBal;
          state.partnerOutStandingBalancedata[
            action.payload.partnerId
          ].outStandingAdv = action.payload.outStandingAdv;
        }
        if (
          typeof action.payload.finalOutStBal !== "undefined" &&
          typeof action.payload.finalLedgerBal !== "undefined"
        ) {
          state.partnerOutStandingBalancedata[
            action.payload.partnerId
          ].finalOutStBal = action.payload.finalOutStBal;
          state.partnerOutStandingBalancedata[
            action.payload.partnerId
          ].finalLedgerBal = action.payload.finalLedgerBal;
        }
        if (action.payload.billId) {
          state.partnerOutStandingBalancedata[action.payload.partnerId].billId =
            action.payload.billId;
        }
        if (action.payload.caBSeq) {
          state.partnerOutStandingBalancedata[action.payload.partnerId].caBSeq =
            action.payload.caBSeq;
        }
      }
    },
    updateBillAmountData: (
      state,
      action: PayloadAction<TAdditionDeduction>
    ) => {
      state.billAmountData = action.payload;
    },
    updateNotifications: (
      state,
      action: PayloadAction<TNotificatons[]>
    ) => {
      state.notifications = action.payload;
    },
    initCustomBillAmount: (
      state,
      action: PayloadAction<{ [id: number]: TCaBillSetting }>
    ) => {
      state.customBillAmountData = action.payload;
    },
    updateCustomBillAmount: (
      state,
      action: PayloadAction<{ settingId: number; setting: TCaBillSetting }>
    ) => {
      state.customBillAmountData[action.payload.settingId] =
        action.payload.setting;
    },
    // bill view methods
    openSingleBillViewModal: (
      state,
      action: PayloadAction<
        TBillInfo & {
          bill_type: "BUY" | "SELL";
          partnerName: string;
          partnerShortName: string;
          fromLedgers?: boolean;
          fromTransporters?: boolean;
          fromSmartChart?: boolean,
          fromSalesSummary?: boolean,
        }
      >
    ) => {
      state.openBillViewModal = true;
      state.bill_id = action.payload.billId;
      state.caBSeq = action.payload.caBSeq;
      state.billCreationMode = "single";
      state.billType = action.payload.bill_type;
      state.partnerName = action.payload.partnerName;
      state.singleBillViewData = action.payload;
      state.multiBillViewData = null;
      state.billStatus = action.payload.billStatus;
      state.partnerShortName = action.payload.partnerShortName;
      state.selectedDate = moment(action.payload.billDate).format("YYYY-MM-DD");
      state.reloadBillTable = false;
      if (action.payload.fromLedgers) {
        state.fromLedgers = action.payload.fromLedgers;
      }
      if (action.payload.fromTransporters) {
        state.fromTransporters = action.payload.fromTransporters;
      }
      if (action.payload.fromSmartChart) {
        state.fromSmartChart = action.payload.fromSmartChart;
      }
      if (action.payload.fromSalesSummary) {
        state.fromSalesSummary = action.payload.fromSalesSummary;
      }
      state.isPendingBill = false;
    },
    openMultiBillViewModal: (
      state,
      action: PayloadAction<TMultiBill & { bill_type: "BUY" | "SELL" }>
    ) => {
      state.billType = action.payload.bill_type;
      state.billCreationMode = "multiple";
      state.group_id = action.payload.groupId;
      state.openBillViewModal = true;
      state.multiBillViewData = action.payload;
      state.singleBillViewData = null;
      state.billStatus = action.payload.billInfo[0].billStatus;
      state.reloadBillTable = false;
      state.selectedDate = moment(action.payload.billInfo[0].billDate).format(
        "YYYY-MM-DD"
      );
      state.isPendingBill = false;
    },
    closeBillViewModal: (state) => {
      return {
        ...initialState,
        openBillViewModal: false,
      };
    },
    editBill: (state, action: PayloadAction<{
      fromAddBill: boolean,
    }>) => {
      state.currentStep = 3;
      state.billEdit = true;
      state.openModal = true;
      state.isPendingBill = false;
      state.fromAddBill = action.payload.fromAddBill;
    },
    setWriter: (state, action) => {
      state.writerById = action.payload;
    },
    // setOriginalBill: (state, action) =>{
    // state.
    // },
    // setBillData: (state, action)=>{
    //  state.billData = action.payload;
    // },
    grpWiseData: (state, action) => {
      state.grpWiseData = action.payload;
    },
    billStatus: (state, action) => {
      state.billStatus = action.payload;
    },
    setMoveNextPartnerStatus: (
      state,
      action: PayloadAction<{ status: boolean }>
    ) => {
      state.moveNextPartner = action.payload.status;
    },
    setCropTableEditStatus: (
      state,
      action: PayloadAction<{
        quantityChangedStatus?: boolean;
        grossTotalChangedStatus?: boolean;
      }>
    ) => {
      if (typeof action.payload.grossTotalChangedStatus !== "undefined") {
        state.grossTotalChanged = action.payload.grossTotalChangedStatus;
      }
      if (typeof action.payload.quantityChangedStatus !== "undefined") {
        state.quantityChanged = action.payload.quantityChangedStatus;
      }
    },
    outStandingBillStatus: (state, action) => {
      state.outStandingBillStatus = action.payload;
    },
    billHistoryData: (state, action) => {
      state.billHistoryData = action.payload;
    },
    updateAddBillGrossTotal: (state, action: PayloadAction<{
      grossTotal: number,
      totalCrop: number,
    }>) => {
      let grossTotal = action.payload.grossTotal;
      let totalCrop = action.payload.totalCrop;
      state.grossTotal = grossTotal;
      state.billAmountData.actualPayble = grossTotal;
      state.cropTotalUnits = totalCrop;
      state.billAmountData.grossTotal = grossTotal;
      state.billAmountData.total = grossTotal;
      state.billAmountData.actualPayble = grossTotal;
      state.billAmountData.actualReceivable = grossTotal;
      state.billAmountData.finalLedgerBal = grossTotal;
      state.billAmountData.finalOutBal = grossTotal;
      state.billAmountData.transportationUnit = totalCrop;
      state.billAmountData.rentUnit = totalCrop;
      state.billAmountData.labourChargesUnit = totalCrop;
      state.billAmountData.totalReceivable = grossTotal;
    },
    editPendingBill: (state, action: PayloadAction<{
      billType: "BUY" | "SELL",
    }>) => {
      state.currentStep = 3;
      state.billEdit = false;
      state.openModal = true;
      state.billCreationMode = 'single';
      state.isPendingBill = true;
      state.billType = action.payload.billType;
    },
    sellOutStandingBillStatus: (state, action) => {
      state.sellOutStandingBillStatus = action.payload;
    },
    buyOutStandingBillStatus: (state, action) => {
      state.buyOutStandingBillStatus = action.payload;
    },
    setSelectedMultiPartnerIndex: (state, action) => {
      state.selectedMultiPartnerIndex = action.payload;
    },
  },
});
export const {
  opeBillCreationModal,
  closeBillCreationModal,
  setCurrentStep,
  setSelectedPartner,
  setSelectedTransporter,
  setSelectedDate,
  changeCropPartner,
  setUniqueBillingCrops,
  setEditCropPartnerId,
  setMoveNextPartnerStatus,
  addCrop,
  removeCrop,
  clonedCrops,
  cropAdditionCompleted,
  updateCropTableStatus,
  updateCropInfo,
  showIdividualWeightModal,
  updateIndividualWeight,
  hideIdividualWeightModal,
  calculateCropsSummary,
  initializeBillData,
  updateOutStandingBalance,
  updateBillAmountData,
  initCustomBillAmount,
  updateCustomBillAmount,
  openSingleBillViewModal,
  openMultiBillViewModal,
  closeBillViewModal,
  grpWiseData,
  editBill,
  billStatus,
  setCropTableEditStatus,
  billHistoryData,
  setWriter,
  updateNotifications,
  updateAddBillGrossTotal,
  editPendingBill,
  outStandingBillStatus,
  sellOutStandingBillStatus,
  buyOutStandingBillStatus,
  setSelectedMultiPartnerIndex,
} = billBookSlice.actions;

export default billBookSlice.reducer;
