import bell from "../../assets/images/navbar/Bell.svg";
import help from "../../assets/images/navbar/Help.svg";
import leftClick from "../../assets/images/left_click.png";
import "./topNavigation.scss";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../reducers/authSlice";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import caImage from "../../assets/images/ca_img.svg";
import { getProfile } from "../../actions/profileService";
import notification from '../../assets/images/notification_bell.svg'
import { PanelSlider } from "../../components/ui";
import Notification from "../../modules/notifications/notification";
import useNotifications from '../../modules/notifications/useNotifications';
import { TRootState } from '../../store';

function TopNavigation() {
  const langData = localStorage.getItem("languageData");
  const langFullData = JSON.parse(langData) || null;

  const linkValue = localStorage.getItem("LinkId");
  var linkPath = localStorage.getItem("LinkPath");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginUserDetails = JSON.parse(localStorage.getItem("loginResponse"));
  const [profileData, setProfileData] = useState(null);
  const writerDetails = JSON.parse(localStorage.getItem("writerInfo"));

  // const getProfileDetails = () => {

  // };
  useEffect(() => {
    getProfile(loginUserDetails.caId)
      .then((response) => {
        setProfileData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [loginUserDetails.caId]);
  const logOutFunction = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    localStorage.setItem("isauth", false);
    dispatch(authActions.logout(false));
    navigate("/login");
    window.location.reload();
    localStorage.removeItem("loginResponse");
    localStorage.removeItem("writerInfo");
    localStorage.removeItem("userType");
    localStorage.removeItem("LinkPath");
    localStorage.removeItem("languageData");
    localStorage.setItem("LinkPath", "/smartboard");
    localStorage.setItem("statusPlan", "FAILURE");
    localStorage.setItem("LinkId", "1");
    localStorage.removeItem("businessCreatedStatus");
  };
  const singleBill = JSON.parse(localStorage.getItem("billData"));

  var billStatus = JSON.parse(localStorage.getItem("billViewStatus"));
  var stepone = JSON.parse(localStorage.getItem("stepOne"));
  var stepOneSingleBill = JSON.parse(localStorage.getItem("stepOneSingleBook"));
  const backToBuyBillBook = () => {
    localStorage.setItem("billViewStatus", false);
    localStorage.setItem("stepOne", false);
    navigate("/buy_bill_book");
  };
  const backToSellBillBook = () => {
    localStorage.setItem("billViewStatus", false);
    localStorage.setItem("stepOneSingleBook", false);
    navigate("/sellbillbook");
  };
  const profileClick = () => {
    localStorage.setItem("LinkPath", "/myprofile");
    localStorage.setItem("LinkId", 8);
    navigate("/myprofile");
    window.location.reload();
  };


  // const { caId } = useSelector((state) => ({
  //   caId: state.auth.loginResponse.caId
  // }));

  const [openNotifi, setOpenNotifi] = useState(false);
  const { notifications, fetchNotifications } = useNotifications();
  // const[refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (openNotifi) {
      fetchNotifications();
    }
  }, [openNotifi]);

  return (
    <>
      <nav className="navbar navbar-expand-lg bg_white main_nav">
        <div className="container-fluid">
          <div className="page_header">
            <h2>
              {/* {(linkValue == 1 || linkPath == "/smartboard") && "Smartboard"} */}
              {linkValue == 1 && "Smartboard"}
              {linkValue == 2 && "Smartchart"}
              {linkValue == 3 && "Sell Bill Book"}
              {linkValue == 4 && "Sell Bill Book"}
              {linkPath == "/buy_bill_book" && "Buy Bill Book"}
              {linkValue == 6 && "Buy Bill Book"}
              {linkValue == 7 && "Buyer Ledger"}
              {linkValue == 8 && "Seller Ledger"}
              {linkPath == "/partner" && "Partners"}
              {linkValue == 10 && "My Profile"}
              {linkValue == 11 && "My Profile"}
              {linkValue == 12 && "Reports"}
              {linkValue == 13 && "Transporto"}
              {linkValue == 14 && "Advances"}
            </h2>
          </div>
          <div className="navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav flex_class">
              <li className="nav-item">
                <div className="nav-link active" aria-current="page" href="#">
                  {/* <form className="d-flex" role="search">
                  <input
                    className="form-control search"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                </form> */}
                </div>
              </li>
              {/* <li className="nav-item">
              <a className="nav-link" href="#">
                <img src={help} alt="icon" />
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link bell_icon" href="#">
                <img src={bell} alt="icon" />
              </a>
            </li> */}
              <li className="nav-item">
                <div className="caDetails">
                  <div className="nav-link" type="button">
                    {loginUserDetails.useStatus === "WRITER" ? (
                      <div className="d-flex align-items-center">

                        <img src={caImage} className="mr-2" alt="image" />
                        <div>
                          <h5>
                            {writerDetails != null
                              ? writerDetails[0].fullName
                              : ""}
                          </h5>
                          <p>
                            Click ID:
                            {loginUserDetails != null
                              ? loginUserDetails.clickId
                              : loginUserDetails.clickId}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <div className="mr-6"
                          onClick={() => {
                            setOpenNotifi(true);
                            // setRefresh(true);
                          }}
                        >
                          <img src={notification} alt="" className="w-8 h-8" />
                        </div>
                        <div
                          className="d-flex align-items-center"
                          onClick={() => profileClick()}
                        >
                          <img src={caImage} className="mr-2" alt="image" />
                          <div>
                            <h5>
                              {profileData != null
                                ? profileData.personalDtls.ownerName
                                : ""}
                            </h5>
                            <p>
                              Click ID:
                              {loginUserDetails != null
                                ? loginUserDetails.clickId
                                : loginUserDetails.clickId}
                            </p>
                          </div>
                        </div>
                      </div>

                    )}
                  </div>
                </div>
                {/* <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {loginUserDetails.profile.profile.fullName}
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <p>Click Id:{loginUserDetails.profile.profile.clickId}</p>
                </li>
                <li>
                  <p>{loginUserDetails.profile.profile.mobile}</p>
                </li>
                <li className="pb-0">
                  <a className="dropdown-item p-0" href="#">
                    <button onClick={logOutFunction} className="primary_btn ">
                      Logout
                    </button>
                  </a>
                </li>
              </ul> */}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <PanelSlider
          direction="right_to_left"
          open={openNotifi}
          style={{ width: "45vw" }}
          showCloseButton={true}
          onClose={() => {
            setOpenNotifi(false);
          }}
        >

          {openNotifi && (
            <div className="py-6 px-4 border-b flex items-center justify-between">
              <div>
                <span className="font-semibold text-lg">Notifications</span>
              </div>
            </div>
          )}

          <div><Notification setOpenNotifi={setOpenNotifi} /></div>
        </PanelSlider>
      </div>

    </>
  );
}

export default TopNavigation;
